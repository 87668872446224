<template>
  <div
    :class="{
      'app-input': true,
      [`app-input-${inputType}`]: true,
      focus: isActive,
      hiddenInput: hidden,
      error
    }"
  >
    <label class="d-block" :for="id">
      <span v-if="staticText" class="static-text">{{ staticText }}</span>
      <input
        v-if="!isTextarea"
        :id="id"
        v-bind="$attrs"
        ref="input"
        autocomplete="new-password"
        class="input"
        :value="modelValue"
        :type="type"
        :data-rule="validationType"
        :data-required="required"
        :class="{
          focus: isActive
        }"
        :readonly="readonly"
        @input="$emit('update:modelValue', $event.target.value)"
        @blur="focusHandler"
        @focus="focusHandler"
        @focusout="focusHandler"
      />

      <textarea
        v-else
        class="input"
        autocomplete="new-password"
        v-bind="$attrs"
        :value="modelValue"
        :readonly="readonly"
        @input="changeHandler($event.target.value)"
        @focus="focusHandler"
        @focusout="focusHandler"
      ></textarea>

      <label v-if="label" :for="id" class="text-label">
        {{ label }}
      </label>

      <span v-if="showControls" class="controls d-flex flex-column">
        <span class="control-btn"><SortButton sort="b" @click="incr"/></span>
        <span class="control-btn" @click="decr"><SortButton sort="a"/></span>
      </span>

      <span v-if="error" class="error-msg">{{ errorMessage }}</span>

      <!-- INPUT STATUS TEXT -->

      <app-input-status
        v-if="status !== null"
        :label="status.label"
        :type="status.type"
      />
    </label>
  </div>
</template>

<script>
import AppInputStatus from "./AppInputStatus.vue";
import SortButton from "@/components/ui/SortButton";

export default {
  name: "AppInput",
  components: { SortButton, AppInputStatus },
  inject: ["inputErrors", "destroyed"],
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: "text",
      validator: t =>
        ["text", "number", "password", "email", "tel"].indexOf(t) !== -1
    },
    isTextarea: { type: Boolean, default: false },
    status: { type: [Object, null], default: null },
    inputType: { type: String, default: "default" },
    id: { type: String, required: true },
    label: { type: String, required: false },
    staticText: { type: String, required: false },
    validators: { type: Array, default: () => [] },
    staticFocused: { type: Boolean, default: false },
    hidden: { type: Boolean, default: false },
    showControls: { type: Boolean, default: false },
    maxValue: { type: Number, default: null },
    minValue: { type: Number, default: 0 },
    readonly: { type: Boolean, default: false },
    validationType: { type: [String, null], default: null },
    preventBrowserAutocomplete: { type: Boolean, default: false },
    required: { type: Boolean, default: true },
    modelValue: {
      type: String,
      default: ""
    }
  },
  emits: ["update:modelValue"],

  data() {
    return {
      focused: this.staticFocused === true || this.preventBrowserAutocomplete,
      needShowControls: this.showControls && this.type === "number"
    };
  },

  computed: {
    isActive() {
      return !!this.focused || this.modelValue.toString().length;
    },

    inputError() {
      if (!this.inputErrors?.value) {
        return null;
      }

      return this.inputErrors.value.find(({ id }) => id === this.id);
    },

    error() {
      if (!this.inputError) {
        return false;
      }
      const { error, touched } = this.inputError;
      return error && touched;
    },

    errorMessage() {
      return this.inputError?.errorMessage || "";
    }
  },

  watch: {
    modelValue: {
      immediate: true,
      handler(value, prevValue) {
        this.$nextTick(() => {
          if (value !== prevValue && this.$refs.input) {
            const event = document.createEvent("HTMLEvents");
            event.initEvent("input", false, true);

            this.$refs.input.dispatchEvent(event);
          }
        });
      }
    }
  },

  unmounted() {
    this.$nextTick(() => {
      if (this.destroyed) {
        this.destroyed(this.id);
      }
    });
  },

  methods: {
    focusHandler({ type }) {
      if (this.staticFocused !== true && !this.readonly) {
        this.focused = type === "focus";
      }
    },

    changeHandler(value = "") {
      this.$emit("update:modelValue", value);
    },

    incr() {
      const intValue = Number(this.modelValue);
      if ((intValue && intValue < this.maxValue) || this.maxValue === null) {
        this.changeHandler(intValue + 1);
      }
    },

    decr() {
      const intValue = Number(this.modelValue);
      if ((intValue && intValue > this.minValue) || this.minValue === null) {
        this.changeHandler(intValue - 1);
      }
    }
  }
};
</script>

<style lang="scss">
.control-btn {
  display: block;
  width: 3em;
  height: 3em;
  font-size: 4px;
  &:not(:last-child) {
    margin-bottom: 2px;
  }

  button {
    font-size: 4px !important;
  }
}

.controls {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translate(0, -50%);
}

.app-input {
  position: relative;
  margin-top: 6px;
  z-index: 1;

  &.hiddenInput {
    opacity: 0;
    pointer-events: none;
    margin: 0 !important;
  }

  &-material {
    $inputHeight: $material-input-height;
    $labelDefaultColor: $grey-200;
    $labelActiveColor: $grey-100;

    .text-label {
      pointer-events: none;
      position: absolute;
      left: $field-padding-x;
      top: $inputHeight / 2;
      transform: translate(-0.7em, -50%);
      line-height: 1;
      padding: 0 0.7em;
      transition: 0.2s;
      color: $labelDefaultColor;
      z-index: $underOverlay;
    }

    &.focus {
      .text-label {
        top: 0;
        font-size: 10px;
        color: $labelActiveColor;
        background-color: #fff;
      }
    }

    .input {
      padding: 1em $field-padding-x;
      height: $inputHeight;
      width: 100%;
      min-height: $inputHeight;
      border: 1px solid $border-color;
      border-radius: $border-radius;
      font-weight: 500;
      transition: 0.2s;
      resize: none;
      overflow: hidden;

      &[readonly] {
        cursor: default;
      }

      &:focus {
        outline: none;
      }

      &[type="password"] {
        font: small-caption;
        font-size: 20px;
      }

      &:not([readonly]) {
        &:focus {
          border-color: $color-primary-blue !important;
          & + label {
            color: $color-primary-blue !important;
          }
        }
      }
    }

    &.error {
      .input {
        border-color: $color-danger;
      }
    }

    .static-text {
      color: $labelDefaultColor;

      & + .input {
        padding: 1em calc(#{$field-padding-x} + 0.6em);

        & + .text-label {
          transform: translate(0.2em, -50%);
        }

        &.focus {
          & + .text-label {
            transform: translate(-0.7em, -50%);
          }
        }
      }
    }

    &.focus {
      .static-text {
        color: inherit;
      }
    }
  }

  .static-text {
    position: absolute;
    top: 50%;
    left: $field-padding-x;
    transform: translate(-50%, -50%);
  }

  textarea:not(.autosize):not(.hidden) {
    min-height: 135px !important;
    overflow: auto;
  }
}
.createUser_error {
  font-size: 12px;
  font-weight: 500;
  margin-top: 0.5em;
  line-height: 1.3;
  display: block;
  padding-left: 15px;
  &:not([readonly]) {
    &:not(:focus) {
      color: $color-danger;
      border: $color-danger;
    }
  }
}
</style>
